export const oFalacias = [
    {
        "id": 1,
        "ejemplo": "No tienes razón porque eres un tonto.",
        "explicación": "Este ataque se centra en insultar a la persona en lugar de responder a su argumento. El hecho de que alguien sea tonto no invalida sus ideas. Este es un ejemplo clásico y fácil de detectar porque el insulto es directo.",
        "consejo_para_detectarla": "Cuando alguien recurre a insultos o descalificaciones personales en lugar de abordar el tema que se está discutiendo, estás ante un Ad Hominem."
    },
    {
        "id": 2,
        "ejemplo": "No puedes hablar de política, ¡tú ni siquiera terminaste la escuela!",
        "explicación": "Aquí se ataca la educación de la persona en lugar de su opinión política. El nivel educativo de alguien no determina la validez de su argumento.",
        "consejo_para_detectarla": "Cuando alguien desacredita una opinión basándose en aspectos personales como la educación o formación, en lugar de enfocarse en el argumento, esto es una falacia Ad Hominem."
    },
    {
        "id": 3,
        "ejemplo": "No deberías escuchar a Juan sobre temas de salud, él fuma.",
        "explicación": "El hecho de que Juan fume no afecta la verdad o falsedad de lo que pueda decir sobre temas de salud. Este ataque personal es irrelevante para la validez de su información.",
        "consejo_para_detectarla": "Si la crítica se basa en un aspecto del estilo de vida o comportamiento personal en lugar de los hechos o argumentos, es una señal de Ad Hominem."
    },
    {
        "id": 4,
        "ejemplo": "Tu argumento sobre economía no cuenta, porque eres muy joven y no tienes experiencia.",
        "explicación": "La juventud de una persona no invalida automáticamente sus ideas o conocimientos sobre economía. El ataque a la edad es irrelevante para el tema.",
        "consejo_para_detectarla": "Si alguien descarta un argumento basándose en la edad, género, o experiencia personal en lugar de analizar el argumento, es una falacia Ad Hominem."
    },
    {
        "id": 5,
        "ejemplo": "No me interesa lo que dices sobre política, ¡eres de un partido corrupto!",
        "explicación": "Atacar la afiliación política de una persona en lugar de discutir su punto de vista específico es un Ad Hominem. Incluso si la afiliación política tiene mala reputación, el argumento merece ser analizado independientemente.",
        "consejo_para_detectarla": "Fíjate si el ataque se dirige a asociaciones o afiliaciones personales en lugar de responder al argumento presentado."
    },
    {
        "id": 6,
        "ejemplo": "No deberías creerle sobre el calentamiento global, ¡es un actor y no un científico!",
        "explicación": "El hecho de que alguien sea actor no significa que lo que dice sobre un tema científico sea falso. El argumento debe evaluarse por sus méritos y no por la ocupación de quien lo presenta.",
        "consejo_para_detectarla": "Pregúntate si se están discutiendo los méritos del argumento o simplemente el trasfondo profesional de quien lo plantea."
    },
    {
        "id": 7,
        "ejemplo": "Por supuesto que dirías eso, eres hombre, así que no puedes opinar sobre feminismo.",
        "explicación": "El género de una persona no invalida automáticamente su opinión sobre un tema. Este tipo de falacia ataca la identidad en lugar del contenido del argumento.",
        "consejo_para_detectarla": "Si el ataque se enfoca en características de la persona que no están relacionadas con la discusión, es Ad Hominem."
    },
    {
        "id": 8,
        "ejemplo": "No deberíamos considerar su argumento sobre derechos humanos, ha sido acusado de evasión de impuestos.",
        "explicación": "La acusación sobre evasión de impuestos, aunque puede afectar su carácter, no refuta directamente su argumento sobre derechos humanos. Es irrelevante al tema en cuestión.",
        "consejo_para_detectarla": "Si un aspecto moral o legal de la vida personal de alguien se usa para evitar discutir el argumento real, es una falacia Ad Hominem."
    },
    {
        "id": 9,
        "ejemplo": "Claro que defiende el capitalismo, es un millonario que se beneficia de él.",
        "explicación": "Aquí se descarta el argumento sobre el capitalismo simplemente porque la persona que lo plantea tiene interés personal. Aun si esa persona se beneficia del sistema, su argumento debe evaluarse de manera objetiva.",
        "consejo_para_detectarla": "Cuando se usa el interés personal para desestimar un argumento sin analizar sus méritos, es un Ad Hominem."
    },
    {
        "id": 10,
        "ejemplo": "No deberíamos escuchar sus puntos sobre cambio climático, ya que en su juventud trabajó para una empresa contaminante.",
        "explicación": "El hecho de que alguien haya trabajado para una empresa con malas prácticas en el pasado no refuta lo que dice ahora. Este es un ejemplo más sutil, ya que se asocia el pasado de la persona con su credibilidad actual.",
        "consejo_para_detectarla": "Si el ataque se basa en algo del pasado que no está directamente relacionado con el argumento actual, es probable que sea una falacia Ad Hominem."
    },
    {
        "id": 11,
        "ejemplo": "Tu opinión sobre la política internacional no cuenta porque nunca has salido del país.",
        "explicación": "El hecho de que alguien no haya viajado no significa que no tenga conocimiento válido sobre política internacional. Es irrelevante al argumento.",
        "consejo_para_detectarla": "Si se usa la falta de experiencia personal para desestimar un argumento sin evaluar su contenido, es un Ad Hominem."
    },
    {
        "id": 12,
        "ejemplo": "No deberíamos escuchar su opinión sobre ética empresarial, él fue despedido por mal comportamiento.",
        "explicación": "El hecho de que alguien haya sido despedido no invalida necesariamente su opinión sobre ética empresarial. El argumento debe ser evaluado por separado.",
        "consejo_para_detectarla": "Si se usa una acción pasada sin relación directa con el argumento actual para desestimarlo, es una falacia Ad Hominem."
    },
    {
        "id": 13,
        "ejemplo": "Claro que apoyas el aumento de impuestos, tú no tienes hijos y no te afecta.",
        "explicación": "La falta de hijos no afecta la validez de un argumento sobre impuestos. El ataque es irrelevante al tema en discusión.",
        "consejo_para_detectarla": "Cuando se atacan las circunstancias personales del hablante en lugar de sus argumentos, es un Ad Hominem."
    },
    {
        "id": 14,
        "ejemplo": "No puedes opinar sobre la crisis ambiental, eres una persona que siempre usa automóvil.",
        "explicación": "El uso del automóvil de una persona no afecta la veracidad de su opinión sobre la crisis ambiental. Este ataque es un intento de descalificación personal.",
        "consejo_para_detectarla": "Si el argumento se rechaza por comportamientos personales en lugar de refutar la información presentada, es una falacia Ad Hominem."
    },
    {
        "id": 15,
        "ejemplo": "No deberíamos escucharte sobre la educación porque no tienes hijos.",
        "explicación": "El hecho de no tener hijos no invalida la capacidad de alguien para opinar sobre temas de educación. Este argumento ataca un aspecto personal en lugar del contenido.",
        "consejo_para_detectarla": "Si se usa la vida personal como una razón para rechazar un argumento, sin abordar el contenido, es una falacia Ad Hominem."
    },
    {
        "id": 16,
        "ejemplo": "¿Cómo puedes hablar sobre derechos de los trabajadores si eres un jefe explotador?",
        "explicación": "Incluso si la persona en cuestión tiene prácticas laborales criticables, sus argumentos sobre derechos de los trabajadores deben analizarse por separado.",
        "consejo_para_detectarla": "Fíjate si el ataque se enfoca en la conducta pasada o actual de la persona sin abordar la lógica del argumento."
    },
    {
        "id": 17,
        "ejemplo": "¿Cómo puedes estar en contra del cambio climático si no tienes estudios en ciencias?",
        "explicación": "El hecho de no tener estudios formales en ciencias no invalida la capacidad de una persona para argumentar en contra o a favor de algo, siempre que ofrezca evidencias.",
        "consejo_para_detectarla": "Si se descalifica un argumento por falta de estudios sin considerar el contenido, es un Ad Hominem."
    },
    {
        "id": 18,
        "ejemplo": "¿Por qué deberíamos escucharte sobre el sistema de salud si nunca te has enfermado gravemente?",
        "explicación": "El hecho de que alguien no haya experimentado una enfermedad grave no es relevante para la validez de su argumento sobre el sistema de salud.",
        "consejo_para_detectarla": "Si se descarta un argumento por la experiencia personal de alguien en lugar de su razonamiento, es una falacia Ad Hominem."
    },
    {
        "id": 19,
        "ejemplo": "Es obvio que apoyas esta reforma fiscal, ¡eres un millonario!",
        "explicación": "Atacar a una persona por ser rica no aborda el contenido de su opinión sobre una reforma fiscal. El ataque se centra en su situación económica y no en la lógica del argumento.",
        "consejo_para_detectarla": "Si el argumento se rechaza por la posición económica del hablante sin refutar lo que dice, es un Ad Hominem."
    },
    {
        "id": 20,
        "ejemplo": "No puedes opinar sobre discriminación racial, eres blanco y nunca lo has sufrido.",
        "explicación": "El hecho de que alguien no haya sufrido discriminación racial no invalida automáticamente sus opiniones sobre el tema. El argumento debe evaluarse de forma objetiva.",
        "consejo_para_detectarla": "Si se descalifica a alguien basándose en su raza o experiencias personales en lugar de analizar su argumento, es una falacia Ad Hominem."
    },
    {
        "id": 21,
        "ejemplo": "Es interesante que defiendas la libertad de expresión en las redes sociales, pero todos sabemos que, como trabajas en una empresa de tecnología, obviamente te beneficias de que se reduzcan las regulaciones. A lo largo de los años, las empresas tecnológicas han sido parte de muchos problemas relacionados con la privacidad, la manipulación de la información y el monopolio del mercado. Entonces, aunque tu argumento pueda parecer razonable, claramente estás defendiendo intereses personales que van más allá del bienestar común, y creo que todos deberíamos estar al tanto de los motivos detrás de tus afirmaciones. Si alguien que trabaja para una empresa tan poderosa como la tuya aboga por menos restricciones, obviamente no es porque estés buscando lo mejor para la sociedad en su conjunto, sino porque estás protegiendo los beneficios económicos de tu compañía y su influencia desmedida en el mercado global."
        ,
        "explicación": "Este es un ataque muy sutil, porque mezcla hechos sobre la profesión de la persona con críticas indirectas a su argumento, insinuando que sus opiniones están motivadas únicamente por intereses personales. Aunque las preocupaciones sobre las empresas tecnológicas pueden ser legítimas, este ataque desvía la atención del argumento principal (la libertad de expresión) y lo convierte en un juicio sobre los intereses económicos del individuo, sin realmente refutar el punto en cuestión.",
        "consejo_para_detectarla": "Para detectar esta falacia, es necesario separar el argumento sobre la libertad de expresión de los ataques a los motivos de la persona que lo presenta. Aunque puede haber conflictos de interés, esto no invalida el contenido del argumento en sí. Debes preguntarte si la crítica se dirige al argumento presentado o a la persona que lo presenta y sus posibles intereses.",
        "por_que_es_dificil_detectar": "Es difícil de detectar porque el ataque está camuflado entre críticas legítimas a las empresas tecnológicas y una insinuación sutil de que el defensor tiene intereses personales. Esto hace que el ataque a la persona sea más difícil de identificar, ya que se presenta bajo una capa de razonabilidad, mezclando hechos con suposiciones sobre las intenciones de la persona."
    },
    {
        "id": 22,
        "ejemplo": "Entiendo que estés proponiendo este plan para mejorar la educación pública, pero debes recordar que cuando trabajabas en el sector privado, tus ideas no dieron resultado. Todos en el consejo saben que en tu anterior puesto de trabajo, los proyectos que lideraste no alcanzaron los objetivos establecidos, lo que afecta tu credibilidad en este tema. Por supuesto, puedes haber cambiado de enfoque, pero las personas deberían considerar que los errores pasados y fracasos no desaparecen de la noche a la mañana. Así que, aunque el plan que propones pueda sonar bien en teoría, tus antecedentes en el manejo de proyectos educativos siguen siendo una razón válida para desconfiar de tu juicio en esta ocasión.",
        "explicación": "Este ataque está camuflado como una crítica a la trayectoria profesional de la persona, en lugar de abordar el contenido del plan educativo propuesto. Se mezclan hechos sobre fallos pasados con insinuaciones de que estos errores personales invalidan cualquier propuesta actual. El argumento principal, que es el plan para mejorar la educación pública, no es refutado en sí mismo, sino que se desacredita indirectamente al cuestionar la capacidad del proponente basado en su historial.",
        "consejo_para_detectarla": "Para detectar esta falacia, es importante separar el historial personal de la persona del contenido de su propuesta actual. Los errores del pasado no necesariamente afectan la validez de los argumentos presentes. Pregúntate si la crítica se centra en los antecedentes personales o en el argumento mismo.",
        "por_que_es_dificil_detectar": "Es difícil de detectar porque combina un ataque a los errores pasados de la persona con una crítica sutil que parece razonable. El foco de atención se desvía del argumento central hacia la credibilidad personal, lo que puede hacer que parezca que el ataque está justificado cuando en realidad se está desviando de la discusión principal."
    },
    
    {
        "id": 1,
        "ejemplo": "Juan dice que deberíamos reducir el consumo de carne para cuidar el medio ambiente, pero eso significa que quiere que todos seamos vegetarianos forzadamente.",
        "explicación": "Se distorsiona la postura de Juan. Él solo sugiere reducir el consumo de carne, pero el argumento se transforma exageradamente en algo más extremo: el vegetarianismo obligatorio.",
        "consejo_para_detectarla": "Fíjate si el argumento original está siendo exagerado o cambiado para que sea más fácil de atacar."
    },
    {
        "id": 2,
        "ejemplo": "María dijo que deberíamos gastar menos en defensa, pero claro, ella quiere que eliminemos el ejército por completo y vivamos desprotegidos.",
        "explicación": "María solo sugirió reducir los gastos en defensa, pero el argumento se exagera a una eliminación total del ejército, lo cual es una distorsión.",
        "consejo_para_detectarla": "Observa si el argumento se ha distorsionado al extremo para ser desacreditado fácilmente."
    },
    {
        "id": 3,
        "ejemplo": "Pedro está a favor del uso de energías renovables. ¡Debe pensar que la energía solar es la única solución para todos nuestros problemas energéticos!",
        "explicación": "Pedro solo apoya las energías renovables, pero su argumento es exagerado al suponer que cree que solo la energía solar puede resolver todos los problemas.",
        "consejo_para_detectarla": "Analiza si se está representando erróneamente la postura de la persona para atacar una versión exagerada."
    },
    {
        "id": 4,
        "ejemplo": "Laura dijo que necesitamos mejorar el transporte público, pero claro, quiere que todo el mundo deje de usar autos y viaje en autobús.",
        "explicación": "Laura está sugiriendo una mejora en el transporte público, pero el argumento se distorsiona hacia la idea de que quiere prohibir el uso de autos.",
        "consejo_para_detectarla": "Revisa si el argumento original se ha cambiado para atacar una versión más extrema."
    },
    {
        "id": 5,
        "ejemplo": "Carlos cree que deberíamos regular el internet para prevenir fraudes, pero eso significa que quiere que el gobierno controle todo lo que hacemos en línea.",
        "explicación": "Carlos solo habla de regulaciones para prevenir fraudes, pero su postura se exagera hasta convertirla en un control total de internet por el gobierno.",
        "consejo_para_detectarla": "Busca si el argumento se ha exagerado hasta un punto extremo y no realista para ser atacado."
    },
    {
        "id": 6,
        "ejemplo": "Ana dice que es importante invertir en educación. Claro, porque según ella, deberíamos gastar todo el presupuesto del país solo en escuelas.",
        "explicación": "Ana solo está sugiriendo más inversión en educación, pero su idea se exagera diciendo que quiere gastar todo el presupuesto en ello.",
        "consejo_para_detectarla": "Analiza si se ha cambiado la propuesta original a una más absurda o extrema."
    },
    {
        "id": 7,
        "ejemplo": "Si apoyas la prohibición del plástico de un solo uso, entonces estás en contra de toda la industria del plástico y quieres que todas las empresas que lo producen cierren.",
        "explicación": "El argumento a favor de la prohibición de plásticos de un solo uso es distorsionado a una versión extrema donde todas las empresas de plástico deben cerrar.",
        "consejo_para_detectarla": "Observa si el argumento ha sido exagerado de manera irreal para hacerlo más fácil de atacar."
    },
    {
        "id": 8,
        "ejemplo": "Si crees que los impuestos deberían aumentar para los ricos, entonces básicamente quieres que los millonarios sean despojados de todo su dinero.",
        "explicación": "El argumento de aumentar los impuestos a los ricos es distorsionado a un extremo donde se sugiere quitarles todo su dinero, lo cual no es la idea original.",
        "consejo_para_detectarla": "Revisa si el argumento se ha exagerado o cambiado para atacar una versión más radical."
    },
    {
        "id": 9,
        "ejemplo": "Marcos dice que deberíamos reciclar más, pero claro, él quiere que pasemos todo nuestro tiempo separando la basura.",
        "explicación": "El argumento de reciclar más se exagera hasta el punto de suponer que Marcos quiere que las personas dediquen todo su tiempo a separar basura.",
        "consejo_para_detectarla": "Analiza si la propuesta original ha sido exagerada o distorsionada para ser ridiculizada."
    },
    {
        "id": 10,
        "ejemplo": "Estás a favor de la energía nuclear, así que seguro apoyas que construyamos plantas nucleares en todas partes, incluso en los parques nacionales.",
        "explicación": "El argumento a favor de la energía nuclear es distorsionado al sugerir que la persona quiere plantas nucleares en todas partes, lo cual no refleja su opinión.",
        "consejo_para_detectarla": "Busca si el argumento ha sido llevado a un extremo irreal o ridículo para facilitar su ataque."
    },
    {
        "id": 11,
        "ejemplo": "Luis propone que reduzcamos la jornada laboral a 35 horas semanales, pero eso es igual a decir que la gente debería trabajar menos y volverse perezosa, lo cual afectaría toda la economía.",
        "explicación": "Luis sugiere reducir la jornada laboral, pero el argumento se distorsiona al insinuar que la gente trabajará menos o será perezosa, lo cual no es la propuesta original.",
        "consejo_para_detectarla": "Fíjate si el argumento original se exagera o distorsiona para atacar una versión que no corresponde al punto real de la discusión."
    },
    {
        "id": 12,
        "ejemplo": "Martina sugiere que usemos más bicicletas en la ciudad para reducir el tráfico, pero eso es lo mismo que querer prohibir los autos, lo cual es totalmente irrealista.",
        "explicación": "El argumento de Martina de usar más bicicletas es distorsionado para hacerlo ver como si quisiera prohibir los autos por completo, lo cual no es su intención.",
        "consejo_para_detectarla": "Observa si el argumento es llevado a un extremo más radical que el original para ser refutado más fácilmente."
    },
    {
        "id": 13,
        "ejemplo": "Estás a favor de aumentar los impuestos a las grandes corporaciones, pero eso significa que quieres que todas las empresas cierren y dejemos de tener empleo.",
        "explicación": "El argumento de aumentar los impuestos a las grandes corporaciones se distorsiona hasta convertirlo en un ataque a todas las empresas, sugiriendo que esto llevará al cierre de las mismas.",
        "consejo_para_detectarla": "Revisa si el argumento es distorsionado o si se le atribuyen consecuencias extremas que no fueron mencionadas en el argumento original."
    },
    {
        "id": 14,
        "ejemplo": "Si propones implementar políticas de igualdad salarial, eso quiere decir que apoyas que todos ganen lo mismo, sin importar el esfuerzo o la experiencia.",
        "explicación": "El argumento de políticas de igualdad salarial es distorsionado al extremo de sugerir que se busca la misma paga para todos, ignorando factores como el esfuerzo o la experiencia.",
        "consejo_para_detectarla": "Observa si el argumento original es exagerado o malinterpretado para hacerlo parecer más extremo de lo que es."
    },
    {
        "id": 15,
        "ejemplo": "Clara está en contra del control excesivo del gobierno en las redes sociales, lo que significa que quiere permitir todo tipo de discurso de odio en línea.",
        "explicación": "El argumento de Clara contra el control gubernamental de las redes se distorsiona como si ella apoyara el discurso de odio, lo cual no es su intención.",
        "consejo_para_detectarla": "Revisa si el argumento ha sido distorsionado para que suene más extremo o inaceptable, cuando en realidad no es lo que se planteaba."
    },
    {
        "id": 16,
        "ejemplo": "Si estás en contra de las cámaras de vigilancia en las calles, eso significa que prefieres que los delincuentes actúen libremente y sin consecuencias.",
        "explicación": "El argumento de estar en contra de las cámaras de vigilancia es distorsionado para hacerlo parecer como si se apoyara a los delincuentes, lo cual no es la postura original.",
        "consejo_para_detectarla": "Analiza si el argumento es cambiado de manera que parezca más extremo o se asocie con consecuencias que no fueron mencionadas."
    },
    {
        "id": 17,
        "ejemplo": "Si dices que el salario mínimo debería aumentar, eso significa que quieres que todos los productos se vuelvan más caros y la inflación se dispare.",
        "explicación": "El argumento a favor de un aumento en el salario mínimo es distorsionado al suponer que causará una inflación masiva, aunque esto no es lo que el proponente está sugiriendo.",
        "consejo_para_detectarla": "Fíjate si el argumento se transforma para sugerir consecuencias extremas que no son parte de la propuesta original."
    },
    {
        "id": 18,
        "ejemplo": "María dice que deberíamos invertir más en cultura, pero eso es lo mismo que querer gastar dinero en cosas inútiles mientras hay problemas más urgentes que atender.",
        "explicación": "El argumento de María sobre invertir más en cultura es distorsionado para hacer parecer que quiere descuidar otros asuntos importantes, lo cual no es su punto.",
        "consejo_para_detectarla": "Revisa si el argumento original es malinterpretado para que parezca menos razonable o irresponsable."
    },
    {
        "id": 19,
        "ejemplo": "Estás a favor de restringir las emisiones de carbono, lo que significa que estás en contra de toda la industria manufacturera y quieres que la economía colapse.",
        "explicación": "El argumento de restringir las emisiones de carbono es exagerado para hacer parecer que el proponente está en contra de toda la industria, lo cual es una distorsión del punto original.",
        "consejo_para_detectarla": "Analiza si el argumento ha sido llevado a un extremo que el proponente no mencionó para hacerlo parecer más radical de lo que realmente es."
    },
    {
        "id": 20,
        "ejemplo": "Si estás a favor de los derechos de los animales, entonces estás diciendo que debemos dejar de comer carne y todos deberían convertirse en veganos.",
        "explicación": "El apoyo a los derechos de los animales es distorsionado al extremo de sugerir que el proponente quiere que todos dejen de comer carne y adopten el veganismo, lo cual no es necesariamente su postura.",
        "consejo_para_detectarla": "Revisa si el argumento ha sido exagerado para parecer más radical de lo que realmente es."
    },
    {
        "id": 21,
        "ejemplo": "Marta dice que deberíamos limitar el uso de los pesticidas más dañinos para proteger la biodiversidad, pero eso significa que ella quiere que los agricultores pierdan su capacidad de producción y aumenten los precios de los alimentos para todos.",
        "explicación": "El argumento de Marta de limitar el uso de pesticidas dañinos es distorsionado hasta parecer que sugiere una reducción drástica en la producción agrícola, algo que ella no mencionó ni insinuó.",
        "consejo_para_detectarla": "Observa si el argumento está siendo distorsionado para sugerir consecuencias extremas que no formaban parte de la propuesta original.",
        "por_que_es_dificil_detectar": "Es difícil de detectar porque mezcla un posible efecto de la propuesta (impacto en la agricultura) con una distorsión más extrema, haciendo que parezca que la propuesta de Marta afectará de manera desproporcionada la producción y los precios de los alimentos."
    },
    {
        "id": 22,
        "ejemplo": "Carlos cree que deberíamos invertir más en energías renovables, pero eso es lo mismo que querer eliminar toda la energía fósil de la noche a la mañana, sin importar las consecuencias para los empleos y la estabilidad económica.",
        "explicación": "El argumento de Carlos a favor de invertir en energías renovables se distorsiona al extremo de hacer parecer que quiere una eliminación inmediata de las energías fósiles, lo cual no es parte de su propuesta original.",
        "consejo_para_detectarla": "Fíjate si el argumento ha sido exagerado para incluir cambios drásticos e inmediatos que no fueron mencionados en la propuesta inicial.",
        "por_que_es_dificil_detectar": "Es difícil de detectar porque se toma una propuesta razonable (inversión en energías renovables) y se le atribuyen consecuencias extremas como la eliminación total e inmediata de las energías fósiles, haciendo que parezca una idea poco realista."
    }

];