// Import the Firebase modular SDK
import { initializeApp } from "firebase/app"
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth"
import {
  getFirestore,
  doc,
  setDoc,
  serverTimestamp,
  collection,
} from "firebase/firestore"

// Class for Firebase Authentication Backend
class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      this.app = initializeApp(firebaseConfig)
      this.auth = getAuth(this.app)
      this.firestore = getFirestore(this.app)

      // Auth state listener
      onAuthStateChanged(this.auth, user => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user))
        } else {
          localStorage.removeItem("authUser")
        }
      })
    } else {
      console.log("No firebaseConfig")
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password)
        .then(userCredential => {
          resolve(userCredential.user)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Edit Profile API (similar to register user)
   */
  editProfileAPI = (email, password) => {
    return this.registerUser(email, password) // Same logic as registering
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then(userCredential => {
          resolve(userCredential.user)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Forget Password with given email
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(this.auth, email, {
        url: window.location.protocol + "//" + window.location.host + "/login",
      })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login with Google or Facebook
   */
  socialLoginUser = async type => {
    let provider
    if (type === "google") {
      provider = new GoogleAuthProvider()
    } else if (type === "facebook") {
      provider = new FacebookAuthProvider()
    }

    try {
      const result = await signInWithPopup(this.auth, provider)
      const user = result.user
      return user
    } catch (error) {
      throw this._handleError(error)
    }
  }

  /**
   * Add new user to Firestore
   */
  addNewUserToFirestore = user => {
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: serverTimestamp(),
      lastLoginTime: serverTimestamp(),
    }

    const userDocRef = doc(this.firestore, "users", user.uid)
    return setDoc(userDocRef, details).then(() => {
      return { user, details }
    })
  }

  /**
   * Set logged in user in localStorage
   */
  setLoggeedInUser = user => {
    console.log("stringify(user)" + JSON.stringify(user))
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Get the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   */
  _handleError(error) {
    return error.message
  }
}

// Singleton FirebaseAuthBackend
let _fireBaseBackend = null

/**
 * Initialize the Firebase backend
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Get the Firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
